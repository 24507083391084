<div class="dialog">
  <h2 mat-dialog-title class="dialog__title">{{ vm()?.title }}</h2>
  <mat-dialog-content class="dialog__content">
    @if (vm()?.badges?.length) {
      <section class="dialog__status-badges">
        @for (badge of vm()?.badges; track badge) {
          <uis-badge [color]="badge.color">{{ badge.text }}</uis-badge>
        }
      </section>
    }
    @if (showTimer()) {
      <section
        class="dialog__task-timer"
        [class.dialog__task-timer__overdue]="timeToCompleteTask()! < 0"
      >
        <uis-timer
          [time]="time()!"
          [isOverdue]="timeToCompleteTask()! < 0"
        ></uis-timer>
      </section>
    }
    <section class="dialog__general-info">
      <uis-info-table [items]="generalInfoItems()" />
    </section>
    @if (item()?.rejectReason; as rejectReason) {
      <section class="dialog__message-box-section">
        <uis-form-field label="Причина відхилення роботи">
          <uis-quote-block [text]="rejectReason" />
        </uis-form-field>
      </section>
    }
    @if (interviewMessage()) {
      <section class="dialog__message-box-section">
        <uis-info-box>{{ interviewMessage() }}</uis-info-box>
        @if (can.Read.User.All() && !!meAsEmployee()) {
          <uis-button
            buttonFill="outline"
            size="small"
            (click)="onGoToStudentPage()"
          >
            Перейти на профіль учня
          </uis-button>
        }
      </section>
    }
    @if (mark(); as mark) {
      <section class="dialog__mark">
        <div
          class="dialog__mark__value-container"
          [class.dialog__mark__value-container_numeric]="
            mark.type === MarkType.Numeric
          "
        >
          <span class="dialog__mark__value">
            @if (mark.type === MarkType.Numeric) {
              <span class="dialog__mark__value__big">
                {{ mark.text.value }}</span
              >
              <span class="dialog__mark__value__normal">
                {{ mark.text.postfix }}</span
              >
            } @else {
              <span class="dialog__mark__value__normal">{{
                mark.text.full
              }}</span>
            }
          </span>
        </div>
        @if (mark.comment) {
          <uis-quote-block [text]="mark.comment" />
        }
      </section>
    }
    @if (assignment(); as assignment) {
      <section class="dialog__task-info">
        <h3 class="dialog__task-info__title">Завдання</h3>
        @if (assignment.description) {
          <p class="dialog__task-info__text">
            {{ assignment.description }}
          </p>
        }
        @if (assignment.attachments.length) {
          <uis-file-list [files]="assignment.attachments" />
        }
      </section>
    }
    @if (correctionHistoryWithoutCurrent().length) {
      <section class="dialog__correction-history-section">
        <button
          class="dialog__correction-history-toggle"
          [class.dialog__correction-history-toggle_opened]="
            correctionHistoryOpened()
          "
          (click)="correctionHistoryOpened.set(!correctionHistoryOpened())"
        >
          {{
            correctionHistoryOpened()
              ? 'Приховати історію доопрацювань'
              : 'Показати історію доопрацювань'
          }}
          <mat-icon inline fontIcon="expand_more" />
        </button>
        <div
          class="dialog__correction-history-section__list"
          [@detailExpand]="correctionHistoryOpened()"
        >
          @for (
            historyEntry of correctionHistoryWithoutCurrent();
            track historyEntry.correctionRequestedAt
          ) {
            <div class="dialog__correction-history-entry">
              <div class="dialog__correction-history-entry__header">
                <span>{{
                  historyEntry.correctionRequestedAt | date: 'dd.MM.YYYY'
                }}</span>
                <span>{{
                  historyEntry.correctionRequestedAt | date: 'HH:mm'
                }}</span>
              </div>
              <div class="dialog__task-info">
                <h3 class="dialog__task-info__title">Відповідь</h3>
                @if (historyEntry.answer?.description) {
                  <p class="dialog__task-info__text">
                    {{ historyEntry.answer.description }}
                  </p>
                }
                @if (historyEntry.answer?.attachments?.length) {
                  <uis-file-list [files]="historyEntry.answer.attachments" />
                }
              </div>
              <uis-quote-block [text]="historyEntry.correctionReason" />
            </div>
          }
        </div>
      </section>
    }
    @if (currentCorrection(); as currentCorrection) {
      <section>
        <div
          class="dialog__correction-history-entry dialog__correction-history-entry_warn"
        >
          <div class="dialog__correction-history-entry__header">
            <span>{{
              currentCorrection.correctionRequestedAt | date: 'dd.MM.YYYY'
            }}</span>
            <span>{{
              currentCorrection.correctionRequestedAt | date: 'HH:mm'
            }}</span>
          </div>
          <div class="dialog__task-info">
            <h3 class="dialog__task-info__title">Відповідь</h3>
            @if (currentCorrection.answer?.description) {
              <p class="dialog__task-info__text">
                {{ currentCorrection.answer.description }}
              </p>
            }
            @if (currentCorrection.answer?.attachments?.length) {
              <uis-file-list [files]="currentCorrection.answer.attachments" />
            }
          </div>
          <uis-quote-block [text]="currentCorrection.correctionReason" />
        </div>
      </section>
    }
    @if (answer(); as answer) {
      <section class="dialog__task-info">
        <h3 class="dialog__task-info__title">
          {{
            correctionHistory().length
              ? 'Допрацьована відповідь'
              : 'Виконане завдання'
          }}
        </h3>
        @if (answer.description) {
          <p class="dialog__task-info__text">
            {{ answer.description }}
          </p>
        }
        @if (answer.attachments.length) {
          <uis-file-list [files]="answer.attachments" />
        }
      </section>
    }
    @if (reviewedAnswer(); as reviewedAnswer) {
      <section class="dialog__task-info">
        <h3 class="dialog__task-info__title">Перевірене завдання</h3>
        @if (reviewedAnswer.description) {
          <p class="dialog__task-info__text">
            {{ reviewedAnswer.description }}
          </p>
        }
        @if (reviewedAnswer.attachments.length) {
          <uis-file-list [files]="reviewedAnswer.attachments" />
        }
      </section>
    }
    @if (ispItemIsLoading$ | async) {
      <div class="dialog__content__loading-layer">
        <mat-progress-spinner mode="indeterminate" color="accent" />
      </div>
    }
  </mat-dialog-content>
  @if (vm()?.actions?.length) {
    <mat-dialog-actions class="dialog__actions">
      @for (action of vm()?.actions; track action) {
        @if (action.onClick) {
          <uis-button
            [disabled]="ispItemIsLoading$ | async"
            (click)="action.onClick()"
            [buttonStyle]="action.buttonStyle"
            >{{ action.text }}
          </uis-button>
        } @else {
          <uis-button
            [disabled]="ispItemIsLoading$ | async"
            [mat-dialog-close]="action.dialogResult"
            [buttonStyle]="action.buttonStyle"
            >{{ action.text }}
          </uis-button>
        }
      }
    </mat-dialog-actions>
  }
</div>
