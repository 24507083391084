import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  getMarkText,
  IndividualStudyPlanItemTask,
  IndividualStudyPlanItemTaskStatus,
  IndividualStudyPlanItemTaskStatusText,
  IndividualStudyPlanItemTaskType,
  IndividualStudyPlanItemTaskTypeText,
  IndividualStudyPlanItemTypeText,
  MarkType,
} from '@uis-models/contract/individual-study-plan';
import { UIS_DIALOG_DATA } from '@uis-common/dialog-container/dialog-container.component';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  InfoTableComponent,
  InfoTableItem,
} from '@uis-common/info-table/info-table.component';
import { FileListComponent } from '@uis-common/files/file-list/file-list.component';
import { BadgeComponent, BadgeConfig } from '@uis-common/badge/badge.component';
import { UserService } from '@uis-services/user/user.service';
import { ButtonComponent } from '@uis-common/button/button.component';
import { IspTaskFile } from '@uis-enums/file-types';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlErrorContainerComponent } from '@uis-common/inputs/infrastrucure/control-error-container/control-error-container.component';
import { FileInputComponent } from '@uis-common/inputs/file-inputs/file-input/file-input.component';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MessageService } from '@uis-services/message/message.service';
import {
  IspDialogContext,
  IspItemTaskDialogActionConfig,
  IspItemTaskViewGeneralVM,
  IspTaskDialogAction,
} from '@uis-private/individual-study-plan/dialogs/isp-dialog.types';
import { DialogService } from '@uis-services/dialog/dialog.service';
import { IndividualStudyPlanService } from '@uis-services/individual-study-plan/individual-study-plan.service';
import {
  BehaviorSubject,
  defer,
  delay,
  EMPTY,
  filter,
  interval,
  map,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { IspInfoBoxComponent } from '@uis-common/info-box/isp-info-box.component';

import { UsersRoutesConfig } from '@uis-private/users/users.routes.config';
import { Router } from '@angular/router';
import { QuoteBlockComponent } from '@uis-common/quote-block/quote-block.component';
import { detailExpand } from '@uis-core/angular-animations/collapse';
import { MatIcon } from '@angular/material/icon';
import { TimerComponent } from '@uis-private/components/timer/timer.component';
import { parseMillisecondsIntoReadableTime } from '@uis-core/helpers/utils';
import { TimeService } from '@uis-services/time/time.service';

@Component({
  selector: 'uis-isp-task-view-dialog',
  imports: [
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    InfoTableComponent,
    FileListComponent,
    BadgeComponent,
    ButtonComponent,
    MatDialogClose,
    ReactiveFormsModule,
    ControlErrorContainerComponent,
    FileInputComponent,
    FormFieldComponent,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    IspInfoBoxComponent,
    QuoteBlockComponent,
    MatIcon,
    TimerComponent,
  ],
  templateUrl: './isp-task-view-dialog.component.html',
  styleUrl: '../../../../../common/dialog.common.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  animations: [detailExpand],
})
export class IspTaskViewDialogComponent {
  public IndividualStudyPlanItemTaskStatus = IndividualStudyPlanItemTaskStatus;
  protected taskDeadlineDate!: Date;
  protected timeToCompleteTask = signal<number | null>(null);
  protected readonly MarkType = MarkType;
  protected readonly IspTaskFile = IspTaskFile;
  private readonly datePipe = inject(DatePipe);
  private readonly router = inject(Router);
  private readonly message = inject(MessageService);
  private readonly dialogRef = inject(MatDialogRef);
  protected readonly dialogService = inject(DialogService);
  private readonly ispService = inject(IndividualStudyPlanService);
  protected readonly can = inject(RoleDataService).can;
  protected readonly meAsEmployee = inject(UserService).meAsEmployee;
  protected readonly meAsStudent = inject(UserService).meAsStudent;
  protected readonly timeService = inject(TimeService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly IndividualStudyPlanItemTaskType =
    IndividualStudyPlanItemTaskType;
  protected readonly correctionHistoryOpened = signal(false);
  protected readonly dialogData = signal(
    inject<IspDialogContext>(UIS_DIALOG_DATA),
  );
  protected readonly ispItemIsLoading$ = new BehaviorSubject(false);
  protected readonly loadedIspItem$ = new BehaviorSubject(
    this.dialogData().item,
  );
  protected readonly item = toSignal(this.loadedIspItem$);
  protected readonly task = computed(() => {
    const item = this.item();
    const taskType = this.dialogData().taskType;
    if (!item || !item.tasks.length) {
      return null;
    }

    return item.tasks.find((task) => task.type === taskType) ?? null;
  });
  protected readonly interviewMessage = computed(() => {
    const isInterview =
      this.task()?.type === IndividualStudyPlanItemTaskType.Interview;

    if (!isInterview) {
      return null;
    }

    const meAsAStudent = this.meAsStudent();
    if (meAsAStudent) {
      return "Протягом наступних 7 робочих днів, після оцінки письмового завдання, у тебе відбудеться співбесіда з вчителем. Найближчим часом вчитель зв'яжеться з тобою для узгодження дати і часу проведення співбесіди.";
    }
    return 'Будь ласка, перейдіть на сторінку профілю учня, де ви зможете знайти контактні дані студента (-ки). Зверніться до учня щодо узгодження дати та часу для співбесіди.';
  });

  protected readonly mark = computed(() => {
    const mark = this.task()?.assessmentResult?.mark;

    if (!mark) {
      return null;
    }

    return {
      ...mark,
      text: getMarkText(mark),
    };
  });

  protected readonly generalInfoItems = computed<InfoTableItem[]>(() => {
    const data = this.dialogData();
    const item = this.item();
    const task = this.task();

    if (!item || !task) {
      return [];
    }

    const isInterview = task.type === IndividualStudyPlanItemTaskType.Interview;
    const meAsStudent = this.meAsStudent();
    const meAsEmployee = this.meAsEmployee();
    this.taskDeadlineDate = new Date(task.date);
    if (
      !isInterview &&
      (task.status === IndividualStudyPlanItemTaskStatus.Available ||
        task.status === IndividualStudyPlanItemTaskStatus.PreviousNotDone)
    ) {
      this.taskDeadlineDate.setDate(this.taskDeadlineDate.getDate() + 1);
    }

    return [
      {
        label: 'Предмет',
        displayedValue: item.subjectData.subject?.name,
      },
      {
        label: 'Вчитель',
        displayedValue:
          meAsStudent ||
          (meAsEmployee && meAsEmployee.id !== item.subjectData.teacher?.id)
            ? item.subjectData.teacher?.fullName
            : null,
      },
      {
        label: 'Учень',
        displayedValue: meAsEmployee
          ? data.individualStudyPlan.student.fullName
          : null,
      },
      {
        label: 'Клас',
        displayedValue: meAsEmployee
          ? `${data.individualStudyPlan.class.classLevel}-й`
          : null,
      },
      {
        label: 'Оцінювання',
        displayedValue: IndividualStudyPlanItemTypeText[item.type],
      },
      {
        label: 'Завдання',
        displayedValue: IndividualStudyPlanItemTaskTypeText[task.type],
      },
      {
        label: isInterview
          ? 'Час початку співбесіди'
          : 'Кінцева дата здачі завдання',
        displayedValue: this.datePipe.transform(
          this.taskDeadlineDate,
          'dd.MM.yyyy (HH:mm)',
        ),
      },
    ].filter((info) => info.displayedValue);
  });

  protected readonly correctionHistory = computed<
    IndividualStudyPlanItemTask['correctionHistory']
  >(() => {
    const task = this.task();
    if (!task) {
      return [];
    }

    return task.correctionHistory;
  });

  protected readonly currentCorrection = computed(() => {
    const task = this.task();
    const correctionHistory = this.correctionHistory();
    if (
      !correctionHistory.length ||
      task?.status !== IndividualStudyPlanItemTaskStatus.CorrectionNeeded
    ) {
      return null;
    }

    return correctionHistory.at(-1)!;
  });

  protected readonly correctionHistoryWithoutCurrent = computed(() =>
    this.correctionHistory().filter(
      (entry) => entry !== this.currentCorrection(),
    ),
  );

  protected readonly vm = computed<IspItemTaskViewGeneralVM | null>(() => {
    const task = this.task();
    const meAsStudent = this.meAsStudent();

    if (!task) {
      return null;
    }

    const isInterview = task.type === IndividualStudyPlanItemTaskType.Interview;
    let statusBadgeColor: BadgeConfig['color'] = 'info';
    let title = 'Перегляд завдання';
    let actions: (IspTaskDialogAction | null)[] = [];

    switch (task.status) {
      case IndividualStudyPlanItemTaskStatus.Created:
        statusBadgeColor = 'warn';
        actions = [
          this.can.Update.IndividualStudyPlanItemAssignment.Own()
            ? IspTaskDialogAction.EditAssignment
            : IspTaskDialogAction.Ok,
          !isInterview &&
          this.can.Update.IndividualStudyPlanItemAssessTask.Own()
            ? IspTaskDialogAction.Evaluate
            : null,
        ];
        break;
      case IndividualStudyPlanItemTaskStatus.Available:
        statusBadgeColor = 'info';
        actions = [
          this.can.Update.IndividualStudyPlanItemReject.Own() && task.isOverdue
            ? IspTaskDialogAction.Reject
            : null,
          isInterview &&
          !this.can.Update.IndividualStudyPlanItem.All() &&
          this.can.Update.IndividualStudyPlanItemInterviewDate.Own()
            ? IspTaskDialogAction.UpdateInterviewDate
            : null,
          this.can.Update.IndividualStudyPlanItemAssignment.Own()
            ? IspTaskDialogAction.EditAssignment
            : null,
          this.can.Update.IndividualStudyPlanItemAssessTask.Own()
            ? IspTaskDialogAction.Evaluate
            : null,
          !isInterview && this.can.Update.IndividualStudyPlanItemStartTask.Own()
            ? IspTaskDialogAction.RequestTask
            : IspTaskDialogAction.Ok,
        ];
        break;
      case IndividualStudyPlanItemTaskStatus.PreviousNotDone:
        statusBadgeColor = 'warn';
        actions = [
          this.can.Update.IndividualStudyPlanItemReject.Own() && task.isOverdue
            ? IspTaskDialogAction.Reject
            : null,
          this.can.Update.IndividualStudyPlanItemAssignment.Own()
            ? IspTaskDialogAction.EditAssignment
            : IspTaskDialogAction.Ok,
        ];
        break;
      case IndividualStudyPlanItemTaskStatus.InProgress:
        statusBadgeColor = 'info';
        actions = [
          this.can.Update.IndividualStudyPlanItemReject.Own() && task.isOverdue
            ? IspTaskDialogAction.Reject
            : null,
          isInterview &&
          !this.can.Update.IndividualStudyPlanItem.All() &&
          this.can.Update.IndividualStudyPlanItemInterviewDate.Own()
            ? IspTaskDialogAction.UpdateInterviewDate
            : null,
          isInterview &&
          this.can.Update.IndividualStudyPlanItemFinishInterview.Own()
            ? IspTaskDialogAction.FinishInterview
            : null,
          !isInterview &&
          this.can.Update.IndividualStudyPlanItemAnswerTask.Own()
            ? IspTaskDialogAction.SubmitAnswer
            : null,
        ];
        break;
      case IndividualStudyPlanItemTaskStatus.InReview:
        statusBadgeColor = 'info';
        if (meAsStudent) {
          actions = [IspTaskDialogAction.Ok];
        } else {
          title = 'Перевірка завдання';
          actions = [
            this.can.Update.IndividualStudyPlanItemReject.Own()
              ? IspTaskDialogAction.Reject
              : null,
            this.can.Update.IndividualStudyPlanItemAssessTask.Own()
              ? IspTaskDialogAction.Evaluate
              : null,
            !isInterview &&
            this.can.Update.IndividualStudyPlanItemRequestTaskCorrection.Own()
              ? IspTaskDialogAction.RequestCorrection
              : null,
          ];
        }
        break;
      case IndividualStudyPlanItemTaskStatus.CorrectionNeeded:
        statusBadgeColor = 'warn';
        actions = [
          this.can.Update.IndividualStudyPlanItemAnswerTask.Own()
            ? IspTaskDialogAction.SubmitAnswer
            : IspTaskDialogAction.Ok,
        ];
        break;
      case IndividualStudyPlanItemTaskStatus.Rejected:
        statusBadgeColor = 'danger';
        actions = [IspTaskDialogAction.Ok];
        break;
      case IndividualStudyPlanItemTaskStatus.Completed:
        statusBadgeColor = 'success';
        if (meAsStudent) {
          actions = [IspTaskDialogAction.Ok];
        } else {
          actions = [
            this.can.Update.IndividualStudyPlanItemAssessTask.Own()
              ? IspTaskDialogAction.EditMark
              : null,
            isInterview &&
            this.can.Update.IndividualStudyPlanItemAssessTask.Own() &&
            !task.assessmentResult
              ? IspTaskDialogAction.Evaluate
              : null,
            IspTaskDialogAction.Ok,
          ];
        }
        break;
    }

    const badges: { text: string; color: BadgeConfig['color'] }[] = [
      {
        text: IndividualStudyPlanItemTaskStatusText[task.status],
        color: statusBadgeColor,
      },
    ];

    if (task.isOverdue && this.timeToCompleteTask()! < 0) {
      badges.push({
        text: 'Завдання протерміновано',
        color: 'danger',
      });
    }

    if (!actions.length) {
      actions = [IspTaskDialogAction.Ok];
    }

    return {
      title,
      badges,
      actions: actions
        .filter((action) => !!action)
        .map((action) => this.ispTaskViewDialogActionConfig[action!])
        .map((action) => {
          action.buttonStyle.size ??= 'small';
          return action;
        }),
    };
  });

  protected readonly assignment = computed(() => {
    const task = this.task();

    if (!task) {
      return null;
    }

    return task.assignment?.description || task.assignment?.attachments.length
      ? task.assignment
      : null;
  });
  protected readonly answer = computed(() => {
    const task = this.task();

    if (!task) {
      return null;
    }

    return task.answer?.description || task.answer?.attachments.length
      ? task.answer
      : null;
  });

  protected readonly reviewedAnswer = computed(
    () => this.task()?.assessmentResult?.reviewedAnswer ?? null,
  );

  protected readonly ispTaskViewDialogActionConfig: {
    [key in IspTaskDialogAction]: IspItemTaskDialogActionConfig;
  } = {
    [IspTaskDialogAction.Ok]: {
      type: IspTaskDialogAction.Ok,
      buttonStyle: {
        color: 'secondary',
      },
      text: 'Зрозуміло',
      dialogResult: null,
    },
    [IspTaskDialogAction.SubmitAnswer]: {
      type: IspTaskDialogAction.SubmitAnswer,
      buttonStyle: {
        fill: 'outline',
        color: 'secondary',
      },
      text: 'Відповісти',
      onClick: () => this.openFormWithAction(IspTaskDialogAction.SubmitAnswer),
    },
    [IspTaskDialogAction.RequestTask]: {
      type: IspTaskDialogAction.RequestTask,
      buttonStyle: {
        color: 'secondary',
      },
      text: 'Почати виконання',
      onClick: () => this.onRequestTask(),
    },
    [IspTaskDialogAction.Reject]: {
      type: IspTaskDialogAction.Reject,
      buttonStyle: {
        fill: 'outline',
        color: 'danger',
      },
      text: 'Відхилення роботи',
      onClick: () => this.openFormWithAction(IspTaskDialogAction.Reject),
    },
    [IspTaskDialogAction.RequestCorrection]: {
      type: IspTaskDialogAction.RequestCorrection,
      buttonStyle: {
        fill: 'outline',
        color: 'warn',
      },
      text: 'Відправити на доопрацювання',
      onClick: () =>
        this.openFormWithAction(IspTaskDialogAction.RequestCorrection),
    },
    [IspTaskDialogAction.Evaluate]: {
      type: IspTaskDialogAction.Evaluate,
      buttonStyle: {
        fill: 'outline',
        color: 'secondary',
      },
      text: 'Оцінити',
      onClick: () => this.openFormWithAction(IspTaskDialogAction.Evaluate),
    },
    [IspTaskDialogAction.EditMark]: {
      type: IspTaskDialogAction.EditMark,
      buttonStyle: {
        fill: 'outline',
        color: 'warn',
      },
      text: 'Редагувати оцінку',
      onClick: () => this.openFormWithAction(IspTaskDialogAction.Evaluate),
    },
    [IspTaskDialogAction.UpdateInterviewDate]: {
      type: IspTaskDialogAction.UpdateInterviewDate,
      buttonStyle: {
        fill: 'outline',
        color: 'secondary',
      },
      text: 'Вказати новий час',
      onClick: () =>
        this.openFormWithAction(IspTaskDialogAction.UpdateInterviewDate),
    },
    [IspTaskDialogAction.EditAssignment]: {
      type: IspTaskDialogAction.EditAssignment,
      buttonStyle: {
        fill: 'outline',
        color: 'gray',
      },
      text: 'Редагувати завдання',
      onClick: () =>
        this.openFormWithAction(IspTaskDialogAction.EditAssignment),
    },
    [IspTaskDialogAction.FinishInterview]: {
      type: IspTaskDialogAction.FinishInterview,
      buttonStyle: {
        fill: 'full',
        color: 'secondary',
      },
      text: 'Завершити',
      onClick: () => {
        const canChangeDate =
          this.can.Update.IndividualStudyPlanItemInterviewDate.Own();
        this.dialogService
          .openDefault({
            size: 'small',
            title: 'Завершити співбесіду?',
            text: `Завершення співбесіди це незворотня дія, після якої ви не зможете розпочати її заново. ${canChangeDate ? 'Якщо ви хочете перенести проходження співбесіди на інший час, скористайтесь функцією "Вказати новий час".' : ''} \n\nВи впевнені, що хочете завершити співбесіду?`,
            rejectText: 'Ні, залишити активною',
            confirmText: 'Так, завершити',
          })
          .subscribe((res) => (res ? this.onFinishInterview() : null));
      },
    },
  };

  protected readonly showTimer = computed(() => {
    const { type, status } = this.task()!;
    const meAsStudent = this.meAsStudent();
    const meAsEmployee = this.meAsEmployee();
    const subjectTeacherId = this.item()?.subjectData.teacher?.id;

    return (
      (meAsStudent || meAsEmployee?.id === subjectTeacherId) &&
      type === IndividualStudyPlanItemTaskType.WrittenAssignment &&
      (status === IndividualStudyPlanItemTaskStatus.InProgress ||
        status === IndividualStudyPlanItemTaskStatus.CorrectionNeeded)
    );
  });

  public time = toSignal(
    interval().pipe(
      filter(() => this.showTimer()),
      takeUntilDestroyed(this.destroyRef),
      map(() => {
        const now = this.timeService.newSyncDate();
        this.timeToCompleteTask.set(
          this.taskDeadlineDate.getTime() - now.getTime(),
        );

        return parseMillisecondsIntoReadableTime(
          Math.abs(<number>this.timeToCompleteTask()),
        );
      }),
      shareReplay(),
    ),
  );

  constructor() {
    if (!this.task()) {
      this.message.error('Такого завдання не інсує');
      this.dialogRef.close(null);
    }
  }

  openFormWithAction(action?: IspTaskDialogAction) {
    this.dialogService
      .openIspItemFormDialog({
        ...this.dialogData(),
        item: this.loadedIspItem$.value,
        action: action,
      })
      .pipe(switchMap((reload) => (reload ? this.reloadIspItem() : EMPTY)))
      .subscribe();
  }

  onRequestTask() {
    const item = this.item();
    const task = this.task();

    if (!item || !task) {
      return;
    }

    this.ispService
      .startIndividualStudyPlanItemTask(item.id, task.type)
      .pipe(switchMap(() => this.reloadIspItem()))
      .subscribe();
  }

  onFinishInterview() {
    const item = this.item();

    if (!item) {
      return;
    }

    this.ispService
      .finishInterview(item.id)
      .pipe(switchMap(() => this.reloadIspItem()))
      .subscribe();
  }

  private reloadIspItem() {
    return defer(() => {
      this.ispItemIsLoading$.next(true);
      return this.ispService.getIndividualStudyPlanItem(this.item()?.id).pipe(
        delay(500),
        tap({
          next: (item) => {
            this.loadedIspItem$.next(item);
          },
          complete: () => this.ispItemIsLoading$.next(false),
        }),
      );
    });
  }

  onGoToStudentPage() {
    defer(() =>
      this.router.navigate([
        UsersRoutesConfig.absolutePath.user(
          this.dialogData().individualStudyPlan.student.id,
        ),
      ]),
    )
      // Closing the dialog after the navigation to prevent
      // query params change navigation on dialog close effect
      // from isp table component to override this navigation
      .pipe(switchMap(() => this.dialogService.closeAll()))
      .subscribe();
  }
}
